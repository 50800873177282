.slideshow {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

.slideshow-wrapper {
  width: 100%;
  height: auto;
  position: relative;
}

/* Slide bleibt im Layout */
.slide {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}

.slide.active {
  opacity: 1;
  position: relative;
  z-index: 1;
}

.slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 90vh;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  border-radius: 12px;
}

/* Buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 50%;
  z-index: 2;
  user-select: none;
  transition: background 0.3s;
  height: 50px;
  width: 50px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* 📱 Mobile Optimierung */
@media screen and (max-width: 768px) {
  .slide img {
    max-height: 80vh;
    width: auto;
    height: auto;
    max-width: 100%;
  }

  .prev,
  .next {
    height: 40px;
    width: 40px;
    font-size: 14px;
  }
}
