.aboutFounder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  background-color: #fcfbf7;
  padding: 40px;
  border-radius: 12px;
}

.aboutFounderText {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.aboutFounderText h2 {
  font-size: 1.8rem;
  color: #3d2c1e;
}

.aboutFounderText p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #5a4335;
}

.aboutFounder img {
  width: 380px;
  height: auto;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.timeLineDiv h2 {
  font-size: 1.8rem;
  color: #3d2c1e;
  text-align: center;
  margin-top: 5rem;
}

@media screen and (max-width: 900px) {
  .aboutFounder {
    flex-direction: row;
    gap: 20px;
  }

  .aboutFounder img {
    width: 300px;
  }

  .aboutFounderText {
    max-width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .aboutFounder {
    flex-direction: column;
    gap: 20px;
  }

  .aboutFounder img {
    width: 100%;
  }
}
