.partners-container {
  text-align: center;
  padding: 40px;
}

.partners-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.partners-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: auto;
}

.partner-logo {
  width: 150px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.partner-logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-logo:hover {
  transform: scale(1.1);
}

.partner-logo-div {
  position: relative;
  display: inline-block;
}

.partner-logo-div::after {
  content: 'Zur Website'; /* Tooltip-Text */
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 8px;
  font-size: 12px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  z-index: 10000;
}

.partner-logo-div:hover::after {
  opacity: 1;
}
