.footerDiv {
  background-color: #2d2d2d;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footerContent {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}

.footerSection {
  flex: 1;
  min-width: 250px;
  margin: 10px;
}

.footerSection h4 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #8b4513;
}

.footerSection p {
  margin: 5px 0;
  font-size: 14px;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.socialIcon {
  font-size: 24px;
  text-decoration: none;
  color: white;
}

.socialIcon:hover {
  color: #8b4513;
}

.footerBottom {
  margin-top: 20px;
  border-top: 1px solid #444;
  padding-top: 10px;
  font-size: 14px;
}

.footerBottom a {
  color: white;
}

.linkDivFooter {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
