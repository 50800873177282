.checkout-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  gap: 40px;
  background-color: #fcfbf7;
}

.order-summary {
  width: 50%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.checkout-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 30px;
}

.checkout-form label {
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0 5px;
  color: #555;
}

input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.checkout-button {
  margin-top: 20px;
  padding: 12px;
  background: #8b4513;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.checkout-button:hover {
  background: #a8561b;
}

.shipping-info,
.customer-info,
.shipping-or-pickup-button {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.order-summary {
  width: 50%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 20px;
}

.order-summary h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 20px;
  color: #333;
}

.order-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: background 0.3s ease-in-out;
}

.order-item:hover {
  background: #f8f8f8;
}

.order-item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 15px;
}

.order-item-details {
  flex-grow: 1;
}

.order-item-details h3 {
  font-size: 16px;
  margin: 0;
  color: #444;
}

.order-item-details p {
  font-size: 14px;
  color: #666;
  margin: 4px 0;
}

.order-summary-total {
  padding-top: 15px;
  border-top: 2px solid #ddd;
  margin-top: 15px;
}

.order-summary-total p {
  font-size: 16px;
  color: #555;
  display: flex;
  justify-content: space-between;
}

.order-summary-total h3 {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.order-summary ul {
  padding: 0;
  list-style: none;
}

.order-confirmation {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.order-confirmation div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}

.order-confirmation button {
  padding: 10px 20px;
  background: #8b4513;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.shipping-or-pickup-button {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.checkout-button-shipping {
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #f0f0f0;
  color: #333;
}

.checkout-button-shipping:hover {
  background-color: #ddd;
}

.checkout-button-shipping.active {
  background-color: #8b4513;
  color: white;
  font-weight: bold;
}

.checkout-button-shipping:disabled {
  background-color: #2d2d2d;
  cursor: not-allowed;
}

/* Responsives Design */
@media (max-width: 768px) {
  .order-summary {
    width: 100%;
  }

  .order-item-image {
    width: 50px;
    height: 50px;
  }

  .order-item-details h3 {
    font-size: 14px;
  }

  .order-item-details p {
    font-size: 12px;
  }

  .order-summary-total p,
  .order-summary-total h3 {
    font-size: 14px;
  }

  .checkout-container {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .checkout-form {
    width: 100%;
  }

  .order-summary {
    width: 100%;
  }
}
