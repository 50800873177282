.abwesend-banner {
  background-color: #2d2d2d;
  color: white;
  text-align: center;
  padding: 2rem;

  width: 100%;
}

.abwesend-banner h1 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.abwesend-banner p {
  font-size: 1.2rem;
  font-weight: 300;
}
