.outerHeader {
  height: auto;
}

.outerHeaderMobile {
  height: auto;
  position: relative;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 20px 50px;
  background-color: #fcfbf7;
  position: relative;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.links a {
  transition: ease-in-out 0.15s;
  text-decoration: none;
  color: black;
}

.links a:hover {
  /* cursor: pointer; */
  cursor: not-allowed;

  margin-right: 3px;
  transform: translate(0, -3px);
  color: #8b4513;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.logo img {
  height: 45px;
  width: auto;
}

.linksIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 80px;
  width: auto;
  gap: 20px;
  position: relative;
  display: inline-block;
}

.linksIcons a {
  max-height: 100%;
  width: auto;
  overflow: hidden;
}

.iconHeader {
  height: 25px;
  width: auto;
}

.cartBadge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  font-size: 10px;
  font-weight: bold;
  border-radius: 50%;
  padding: 2px 5px;
}

.burgerButtonHeader {
  background-color: none;
  border: none;
  background: none;
  transition: ease-in-out 0.1s;
  color: black;
}

.burgerButtonHeader:hover {
  cursor: pointer;
  scale: 1.1;
}

.burgerButtonHeader:active {
  scale: 0.9;
}

.menuOpenDiv {
  position: fixed;
  top: 80px; /* Startet direkt unter dem Header */
  left: 0;
  width: 100%;
  height: calc(100vh - 80px); /* 100vh abzüglich der Header-Höhe */
  background-color: #fcfbf7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding-bottom: 20px;
  transition: ease-in-out 0.3s;
}

.closeButtonHeader {
  margin-top: 20px;
}

.linksMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.linksMobile a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  border-radius: 10px;
  background-color: white;
  width: 90%;
  transition: ease-in-out 0.1s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-decoration: none;
  color: black;
}

.linksMobile a:hover {
  background-color: lightgray;
  margin-bottom: 10px;
  cursor: pointer;
}

.linksMobile a:active {
  background-color: gray;
}

@media screen and (max-width: 800px) {
  .logo {
    left: 0;
    right: auto;
    transform: translate(50%, 0);
  }

  .header {
    padding: 20px 20px;
    flex-direction: row-reverse;
  }
}
