.heroDiv {
  width: 100%;
  height: calc(100vh - 80px); /* Gesamthöhe des Viewports abzüglich Header */
  background-size: cover; /* Stellt sicher, dass das Bild den gesamten Bereich füllt */
  background-position: center; /* Zentriert das Bild */
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Weißer Text für besseren Kontrast */
  text-align: center;
  position: relative;
}

/* Optional: Ein leichtes Overlay für bessere Lesbarkeit */
.heroDiv::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Dunkles Overlay */
}

/* Stellt sicher, dass der Inhalt über dem Overlay bleibt */
.heroContent {
  position: relative;
  z-index: 1;
  max-width: 80%;
}

.heroContent h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.heroContent p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.heroButton {
  padding: 7px 24px;
  font-size: 1.2rem;
  color: white;
  background: #8b4513; /* Kaffee-braun */
  border: #8b4513 6px solid;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.heroButton:hover {
  background: none; /* Dunkleres Braun */
  border: #8b4513 6px solid;
}
