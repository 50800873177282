.productDetailContainer {
  width: 100%;

  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productImageContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
}

.productThumbnaileImageContainer {
  width: 100%;
  height: auto;
}

.productThumbnaileImageContainer img {
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: contain;
}

.productMainImageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 150px;
  width: 100%;
  overflow-x: auto; /* Scroll aktivieren */
  white-space: nowrap; /* Verhindert das Umbrüche entstehen */
  scroll-behavior: smooth; /* Optional: Weiches Scrollen */
  padding-bottom: 5px; /* Platz für Scrollbar */
  gap: 10px;
}

.productMainImageContainer img {
  height: 100%;
  width: auto;
  border: 1px solid black;
  border-radius: 5px;
  object-fit: contain;
  flex-shrink: 0; /* Verhindert, dass die Bilder verkleinert werden */
}

.productDetailInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: auto;
}

.productDetailInfoInnerContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.productPrice {
  font-size: 24px;
  color: #8b4513;
  font-weight: bold;
}

.productDescription {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

.fullSizeImageContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullSizeImageContainer img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
  overflow: hidden;
}

.product-detail-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  width: 300px;
  width: 90%;
  max-width: 600px;
}

.select-group {
  display: flex;
  flex-direction: column;
}

.select-group input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.select-group label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.product-detail-button {
  padding: 10px;
  background: #8b4513;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.product-detail-button:hover {
  background: #9b4d15;
}

.cartConfirmationMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  backdrop-filter: blur(10px);
}

.cartConfirmationMessageInnerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cartConfirmationMessageInnerDiv button {
  padding: 10px;
  background: #8b4513;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s ease-in-out;
}

.cartConfirmationMessageInnerDiv button:hover {
  background: #9b4d15;
  scale: 1.05;
}

@media screen and (max-width: 1000px) {
  .productDetailContainer {
    flex-direction: column;
  }

  .productImageContainer {
    width: 100%;
    flex-direction: row-reverse;
    gap: 10px;
  }

  .productDetailInfoContainer {
    width: 100%;
  }

  .productThumbnaileImageContainer {
    width: 80%;
  }

  .productThumbnaileImageContainer img {
    height: 100%;
    width: auto;
    max-width: 100%;
  }

  .productMainImageContainer {
    flex-direction: column;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    width: 20%;
    height: 500px;
  }

  .productMainImageContainer img {
    height: auto;
    width: 100%;
  }
}
