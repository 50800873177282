.cart-container {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto;
  height: auto;
  padding: 60px 20px;

  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.back-to-shop {
  display: block;
  text-align: center;
  margin-top: 40px !important;
  font-size: 14px;
  color: black;
  text-decoration: none;
  background-color: #2d2d2d;
  padding: 10px 20px;
  border-radius: 6px;
  color: white !important;
}

.cart-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.cart-container ul {
  width: 100%;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #fcfbf7;
  margin-bottom: 10px;
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 80%;
}

.cart-item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.cart-item-details {
  flex-grow: 1;
  margin-left: 15px;
}

.cart-item-actions input {
  width: 50px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.cart-item-actions p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
  margin: 0;
  margin-bottom: 10px;
}

.cart-item-details h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.cart-item-details p {
  margin: 3px 0;
  font-size: 14px;
  color: #555;
}

.remove-button {
  background: #ff4d4d;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.remove-button:hover {
  background: #cc0000;
}

.clear-cart-button {
  display: block;
  width: 80%;
  padding: 10px;
  background: #8b4513;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  transition: background 0.3s ease;
}

.clear-cart-button:hover {
  background: #a8561b;
}

.back-to-shop {
  display: block;
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  color: black;
  text-decoration: none;
}

.back-to-shop:hover {
  text-decoration: underline;
}

@media screen and (max-width: 700px) {
  .cart-item {
    width: 100%;
  }

  .cart-item-details {
    margin-left: 10px;
  }
}
