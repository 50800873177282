.blogPost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  height: auto;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  overflow: hidden;
}

.blogPostContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 🔹 Container für Bilder */
.blogPostImageDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px; /* Abstand zwischen Bildern */
  position: relative;
  overflow-x: auto;
  padding: 10px;
}

/* 🔹 Bilder teilen sich dynamisch die Breite */
.blogPostImage {
  height: auto;
  max-height: 200px;
  object-fit: contain;
  border-radius: 5px;
  transition: ease-in-out 0.15s;
}

.blogPostImage:hover {
  scale: 1.05;
  margin-left: 10px;
  margin-right: 10px;
}

.blogPostDateDiv p {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

/* 🔹 Info-Box unter den Bildern */
.blogPostInfoDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  background-color: rgb(240, 240, 240);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  gap: 10px;
}

.blogPostInfoDiv h3 {
  margin: 0;
}

.blogPostInfoDiv p {
  margin: 0;
  margin-bottom: 5px;
  text-align: left;
}

.blogPostTitleAndDate {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
}

.blogPostTitleAndDate p {
  font-size: 0.8rem;
  margin: 0;
  color: rgb(84, 84, 84);
}

.blogPostTitleAndDate h3 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.imageCloseUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}

.imageCloseUp img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  border-radius: 20px;
  overflow: hidden;
}

.arrowButton {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: ease-in-out 0.2s;
}

.arrowButton:hover {
  background-color: rgb(240, 240, 240);
  scale: 1.1;
}
