.aktuellesPage {
  text-align: center;
  background-color: #fcfbf7; /* Passendes dunkleres Beige */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aktuellesPageImageDiv {
  height: 400px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-position: center;
}

.textDivAktuellesPage {
  color: white;
  position: absolute;
  bottom: 50px;
  left: 100px;
}

.textDivAktuellesPage h1 {
  font-size: 2rem;
  font-weight: 400;
}

/* Grid für Blog-Posts */
.blogList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 60px;
  gap: 40px;
}

.sortContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  padding: 20px 0px;
}

.sortContainer select {
  padding: 10px;
  margin-left: 10px;
  border: 2px solid;
  border-radius: 5px;
  background-color: #fcfbf7;
}

.sortContainer select:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .textDivAktuellesPage {
    bottom: 20px;
    left: 20px;
    text-align: left;
  }

  .textDivAktuellesPage h1 {
    font-size: 1.5rem;
  }
}
