#bestseller {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 30px 30px;
}

#bestseller h1 {
  text-align: center;
  width: 100%;
  font-size: 2rem;
  margin-bottom: 40px;
  color: #333;
}

.bestseller-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bestseller-image {
  width: auto;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.bestseller-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  margin-left: 20px;
  border-left: #333 solid 1px;
  padding-left: 10px;
}

.bestseller-title {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #333;
}

.bestseller-description {
  font-size: 0.9rem;
  color: #666;
}

.bestseller-button {
  background-color: #8b4513;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.bestseller-button:hover {
  background-color: #824112;
}

@media screen and (max-width: 768px) {
  #bestseller {
    padding: 20px 20px;
  }

  #bestseller h1 {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }

  .bestseller-card {
    flex-direction: column;
    padding: 10px;
  }

  .bestseller-image {
    height: 150px;
  }

  .bestseller-info {
    margin-left: 0;
    border-left: none;
    padding-left: 0;
    margin-top: 10px;
  }

  .bestseller-title {
    font-size: 1rem;
  }

  .bestseller-description {
    font-size: 0.8rem;
  }
}
