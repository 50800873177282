body {
  font-family: "Roboto";
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.App {
  height: 100vh;
}

#slideshow {
  padding: 20px;
}
