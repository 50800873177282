.produktCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 20px;
  text-align: center;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  width: 350px;
  height: 450px;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.productCard:hover {
  cursor: pointer;
}

/* Bild bleibt gleich */
.produktBild {
  width: 100%;
  height: 250px;
  object-fit: contain;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
}

.produktBild:hover {
  transform: scale(1.05);
}

/* Info-Div bleibt klein */
.infoDivProduct {
  background-color: #fcfbf7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px; /* Standardhöhe */
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  color: black;
  padding: 10px 0;
  transition: ease-in-out 0.1s;

  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* Text bleibt sichtbar, aber der Preis ist standardmäßig versteckt */
.infoDivProduct h2 {
  margin: 0;
  font-size: 1.2rem;
}

.infoDivProduct p {
  opacity: 0;
  margin: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* 🟢 Beim Hover wächst die Info-Box nach oben */
.produktCard:hover .infoDivProduct {
  height: 120px; /* Erhöht sich */
  padding: 15px 0; /* Mehr Platz für Inhalt */
  background-color: #8b4513;
}

/* 🟢 Beim Hover wird der Preis sichtbar */
.produktCard:hover .infoDivProduct p {
  opacity: 1;
  transform: translateY(0);
  margin-bottom: 0;
}
