/* Container für die gesamte Timeline */
.timeline-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
}

/* Bildbereich mit Hochformat-Unterstützung */
.timeline-image {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 350px;
}

.timeline-image img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

/* Timeline-Bereich */
.timeline {
  flex: 2;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Einzelner Meilenstein */
.timeline-item {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  position: relative;
}

/* Jahr in der Timeline */
.timeline-year {
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #3d2c1e;
  color: white;
  padding: 10px 16px;
  border-radius: 20px;
  min-width: 90px;
  text-align: center;
  z-index: 10;
}

/* Text des Meilensteins */
.timeline-content {
  background-color: #fcfbf7;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  line-height: 1.5;
  color: #5a4335;
  max-width: 500px;
  z-index: 10;
}

/* Linie zwischen den Items */
.timeline-item::before {
  content: '';
  position: absolute;
  left: 35px;
  top: 50%;
  width: 4px;
  height: 100%;
  background-color: #3d2c1e;
  transform: translateX(-50%);
}

.timeline-item:last-child::before {
  height: 0;
}

/* Responsive Anpassung für mobile Geräte */
@media (max-width: 900px) {
  .timeline-container {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .timeline-image {
    max-width: 80%;
    text-align: center;
  }

  .timeline {
    width: 100%;
    padding-left: 0;
  }

  .timeline-item {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .timeline-year {
    min-width: auto;
    text-align: left;
  }

  .timeline-item::before {
    display: none;
  }
}
