.datenschutzContainer {
  max-width: 800px;
  margin: auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.datenschutzContainer h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #2d2d2d;
}

.datenschutzContainer h2 {
  margin-top: 20px;
  color: #5a5a5a;
}

.datenschutzContainer p {
  margin-bottom: 15px;
}

.datenschutzContainer ul {
  padding-left: 20px;
}

.datenschutzContainer a {
  color: #8b4513;
  text-decoration: none;
}

.datenschutzContainer a:hover {
  text-decoration: underline;
}

.footerText {
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  color: #777;
}
