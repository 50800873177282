.productPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 80px);
  height: auto;
  background-color: #fcfbf7;
  padding-bottom: 50px;
}

.productList {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(350px, 1fr)
  ); /* Dynamisches Grid */
  gap: 30px;
  width: 90%;
  max-width: 1200px;
  justify-items: center;
}

.productPageImageDiv {
  height: 400px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-position: center;
}

.textDivProductPage {
  color: white;
  position: absolute;
  bottom: 50px;
  left: 100px;
}

.textDivProductPage h1 {
  font-size: 2rem;
  font-weight: 400;
}

.searchContainer {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  width: 90%;
  max-width: 500px;
  align-items: center;
  gap: 10px;
}

.searchContainer input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid;
  border-radius: 5px;
  outline: none;
}

.noResults {
  text-align: center;
  font-size: 18px;
  color: #777;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .productList {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .textDivProductPage {
    bottom: 30px;
    left: 50px;
  }

  .textDivProductPage h1 {
    font-size: 1.5rem;
  }

  .searchContainer {
    gap: 5px;
  }

  .searchContainer input {
    font-size: 14px;
  }
}
