.unternehmenswerteDiv {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Standard: 4 Spalten */
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fcfbf7;
  gap: 20px; /* Abstand zwischen den Elementen */
  padding: 20px;
}

/* Unternehmenswert */
.unternehmenswert {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: 30px;
  min-width: 200px;
}

.unternehmenswert img {
  width: 60px;
  height: auto;
}

/* Medium Screens: 2 Spalten */
@media screen and (max-width: 850px) {
  .unternehmenswerteDiv {
    grid-template-columns: repeat(2, 1fr); /* 2 Spalten */
  }
}

/* Kleine Screens: 1 Spalte */
@media screen and (max-width: 500px) {
  .unternehmenswerteDiv {
    grid-template-columns: repeat(1, 1fr); /* 1 Spalte */
  }
}
